import { graphql, useStaticQuery } from "gatsby";

export const useRegenerationPhotos = () => {
  const query = useStaticQuery(graphql`
    query {
      hero: file(
        relativePath: { eq: "image/regeneration/regeneration_hero.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img1: file(relativePath: { eq: "image/regeneration/regeneration.png" }) {
        childImageSharp {
          fluid(maxHeight: 450, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return query;
};
