import React from "react";
import Regeneration from "sections/Regeneration/Regeneration";
import { useEngRegenerationData } from "lang/en/regeneration";

const RegenerationPage = () => {
  const data = useEngRegenerationData();
  return <Regeneration data={data} />;
};

export default RegenerationPage;
