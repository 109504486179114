import { useRegenerationPhotos } from "shared/Hooks/regeneration/useRegenerationPhotos";

export const useEngRegenerationData = () => {
  const photos = useRegenerationPhotos();

  return {
    metaInfo: {
      title: "Tool reconditioning",
      ogDescription:
        "Entrust us with your used tool kit and save up to 70% compared to buying new tools. We professionally recondition cutters, drills and other cutting tools.",
    },
    hero: {
      title: "Tool reconditioning",
      subtitle:
        "Entrust us with your used tool kit and save up to 70% compared to buying new tools. We professionally recondition cutters, drills and other cutting tools.",
      bg: photos.hero,
    },
    content: {
      title: "We restore the efficiency of tools.",
      subtitle:
        "Our tool reconditioning service allows you to recreate the original geometry and coating. Thanks to reconditioning, old used tools regain their efficiency, enabling you to use them effectively over their next life cycle. Reconditioning is definitely a more economical solution than buying a new set of tools. We invite you to use our services. To learn more, please contact us.",
      src: photos.img1,
      bigImg: true,
    },
  };
};
