import React from "react";
import Section from "components/Section/Section";
import Hero from "components/Hero/Hero";
import SEO from "components/seo";

import {SectionsWrappper} from "../../shared/Wrappers/wrappers";

const Regeneration = ({data}) => {
  return (
    <>
      <SEO
        title={data.metaInfo.title}
        description={data.metaInfo.ogDescription}
      />
      <Hero data={data.hero} color="black">
      </Hero>
      <SectionsWrappper>
        <Section data={data.content}/>
      </SectionsWrappper>
    </>
  );
};

export default Regeneration;
